import { Box, Button, Link, styled } from '@mui/material'
import { Typography } from '@carto/meridian-ds/components'
import CancelIcon from 'assets/icons/icon-navigation-cancel.svg?react'
import ActionExploreIcon from 'assets/icons/icon-action-explore-off.svg?react'
import { FormattedMessage } from 'react-intl'
import { isInIframe } from 'app/utils/embedding'
import { useSearchParams } from 'react-router-dom'

const Root = styled(Box, { shouldForwardProp: (propName) => propName !== 'code' })<{ code: keyof typeof ERRORS }>(
  ({ theme, code }) => ({
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(18.5),
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(150)
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(170),
      paddingLeft: 0,
      paddingRight: 0
    },
    ...(code !== '404' && code !== '500'
      ? { display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: theme.spacing(4) }
      : {})
  })
)

const ERRORS = {
  '404': {
    icon: <ActionExploreIcon />,
    title: <FormattedMessage id="common.errors.404.title" />,
    subtitle: <FormattedMessage id="common.errors.404.subtitle" />
  },
  '500': {
    icon: <CancelIcon />,
    title: <FormattedMessage id="common.errors.500.title" />,
    subtitle: <FormattedMessage id="common.errors.500.subtitle" />
  }
}

interface ErrorPageProps {
  code: keyof typeof ERRORS
}
function ErrorPage({ code }: ErrorPageProps) {
  const error = ERRORS[code]
  const [searchParams] = useSearchParams()

  const redirectTo = searchParams.get('redirectTo') || '/'

  const contactLink = {
    link: (
      <Typography color="textPrimary" component={'span'}>
        <Link href={'mailto:support@carto.com'}>
          <FormattedMessage id="common.errors.other.linkLabel" />
        </Link>
      </Typography>
    )
  }

  return (
    <Root code={code}>
      {error.icon}
      <Box mt={1.5}>
        <Typography variant={code ? 'h2' : 'subtitle1'}>
          <FormattedMessage id="common.errors.title" values={{ code: code ? code : 500 }} />. {!code && error.title}
        </Typography>
      </Box>
      {code && <Typography variant="h5">{error.title}</Typography>}
      <Box mt={2} mb={4} textAlign={!code ? 'center' : 'unset'}>
        <Typography color="textSecondary" variant="body1">
          {error.subtitle}
        </Typography>
        {!code && (
          <Typography color="textSecondary" variant="body1">
            <FormattedMessage id="common.errors.other.contact" values={contactLink} />
          </Typography>
        )}
      </Box>
      {code && (
        <Link href={redirectTo} underline="none" target={isInIframe ? '_blank' : undefined}>
          <Button size="large" variant="contained" color="primary">
            <FormattedMessage id="common.errors.back" />
          </Button>
        </Link>
      )}
    </Root>
  )
}

export default ErrorPage
